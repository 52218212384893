import * as React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import AttributeGrid from '../components/AttributeGrid';
import Container from '../components/Container';
import Hero from '../components/Hero';
import BlogPreviewGrid from '../components/BlogPreviewGrid';
import Highlight from '../components/Highlight';
import Layout from '../components/Layout/Layout';
import ProductCollectionGrid from '../components/ProductCollectionGrid';
import ProductCardGrid from '../components/ProductCardGrid';
import Quote from '../components/Quote';
import Title from '../components/Title';
import Badge from 'react-bootstrap/Badge';

import { generateMockBlogData, generateMockProductData } from '../helpers/mock';

import * as styles from './index.module.css';
import { Link, navigate } from 'gatsby';

const IndexPage = () => {
  const newArrivals = generateMockProductData(3, 'shirt');
  const blogData = generateMockBlogData(3);

  const goToShenClinic = () => {
    navigate('/shenclinic');
  };
  const goToTCherbs = () => {
    navigate('/tcherbs');
  };


  return (
    <Layout disablePaddingBottom title="Home">
      {/* Hero Container */}
      <Hero
        maxWidth={'750px'}
        image={'/background/orange_rose_bg.jpg'}
        title={'TC Herbs & Health'}
        subtitle={'泰昌中醫藥中心'}
        ctaText={'Learn more about TC Herbs & Health'}
        ctaText2={'Learn more about Shen Clinic'}
        ctaAction={goToTCherbs}
        ctaAction2={goToShenClinic}
      />

      {/* Message Container */}
      {/* <div className={styles.messageContainer}>
        <p>
          This is a demonstration of the Sydney theme for verse by{' '}
          <span className={styles.gold}>matter design.</span>
        </p>
        <p>
          wear by <span className={styles.gold}>sunspel</span> and{' '}
          <span className={styles.gold}>scotch&soda</span>
        </p>
      </div> */}

      {/* Collection Container */}
      <br />
      <br />
      <div className={styles.collectionContainer}>
        <Container size={'large'}>
          <Title name={'TC Herbs & Health + Shen Clinic '} subtitle={'TC Herbs & Health, a store with herbal remedies and health supplements, and Shen Clinic, a clinic with a variety of traditional Chinese medicine treatments, together offer a variety of treatments and products to help you achieve optimal health and well-being'} />
          <br />
          <br />

          <ProductCollectionGrid />
        </Container>
      </div>

      {/* New Arrivals */}
      {/* <div className={styles.newArrivalsContainer}>
        <Container>
          <Title name={'New Arrivals'} link={'/shop'} textLink={'view all'} />
          <ProductCardGrid
            spacing={true}
            showSlider
            height={480}
            columns={3}
            data={newArrivals}
          />
        </Container>
      </div> */}

      {/* Highlight  */}
      <div className={styles.highlightContainer}>
        <Container size={'large'} fullMobile>
          <Highlight
            image={'/drshen/drshen.jpg'}
            altImage={'Dr. Shen Portrait'}
            title={'Meet Dr. Shen'}
            description={`Dr. Shen is one of the most highly respected and educated professionals in the field of Herbal Medicine and Acupuncture. He has been published in approximately 40 peer-reviewed scientific papers including the top academic journals of both Western and Oriental Medicine.`}
            textLink={'Learn More About Dr. Shen'}
            link={'/shenclinic/#drshen'}
          />
        </Container>
      </div>

      {/* Promotion */}
      {/* <div className={styles.promotionContainer}>
        <Hero image={'/banner2.png'} title={`-50% off \n All Essentials`} />
        <div className={styles.linkContainers}>
          <Link to={'/shop'}>WOMAN</Link>
          <Link to={'/shop'}>MAN</Link>
        </div>
      </div> */}

      {/* Quote */}
      <Quote
        bgColor={'#f2faf3'}
        title={'Patient Testimonial'}
        quote={
          `"Dr. Shen is very kind, personable, very gentle, and very wise. I'm a very happy patient and I'm going to continue coming back." - Gina`
        }
      />

      

      {/* Promotion */}
      {/* <div className={styles.sustainableContainer}>
        <Hero
          image={'/banner3.png'}
          title={'We are Sustainable'}
          subtitle={
            'From caring for our land to supporting our people, discover the steps we’re taking to do more for the world around us.'
          }
          ctaText={'read more'}
          maxWidth={'660px'}
          ctaStyle={styles.ctaCustomButton}
        />
      </div> */}

      {/* Social Media */}
      <div className={styles.socialContainer}>
        <Title
          name={'Photo Gallery'}
          subtitle={'Take a look inside our store!'}
        />
        <div className={styles.socialContentGrid}>
          <img src={`/tcherbs/storeFront.jpg`} alt={'TC Herbs Store Outside'} />
          <img src={`/tcherbs/storeInside3.jpg`} alt={'TC Herbs Store Interior with shelves of products'} />
          <img src={`/tcherbs/herbs.jpg`} alt={'TC Herbs assortment of herbs over 600'} />
          <img src={`/tcherbs/room.jpg`} alt={'Shen Clinic Patient Room'} />
        </div>
      </div>
      <AttributeGrid />

      {/* Blog Grid */}
      {/* <div className={styles.blogsContainer}>
        <Container size={'large'}>
          <Title name={'Journal'} subtitle={'Notes on life and style'} />
          <BlogPreviewGrid data={blogData} />
        </Container>
      </div> */}
      <br/>
    </Layout>
  );
};

export default IndexPage;
