import React from 'react';
import * as styles from './ProductCollectionGrid.module.css';

import ProductCollection from '../ProductCollection';

const ProductCollectionGrid = (props) => {
  return (
    <div className={styles.root}>
      <ProductCollection
        image={'/tcherbs/storeinside1.jpg'}
        title={'TC Herbs and Health'}
        text={'Learn More'}
        link={'/tcherbs'}
      />
      <ProductCollection
        image={'/shenclinic/consultation.jpg'}
        title={'Shen Clinic'}
        text={'Learn More'}
        link={'/shenclinic'}
      />
    </div>
  );
};

export default ProductCollectionGrid;
