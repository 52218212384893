import { Link } from 'gatsby';
import React from 'react';
import * as styles from './Highlight.module.css';

const Highlight = (props) => {
  const {
    image,
    altImage,
    miniImage,
    miniImageAlt,
    title,
    description,
    textLink,
    link,
  } = props;

  return (
    <div className={styles.root}>
      <img alt={altImage} src={image} className={styles.highlightImage} />
      <div className={styles.contentContainer}>
        <h3>{title}</h3>
        <p>{description}</p>
        <Link to={link}>{textLink}</Link>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/9gbQjLx-pKA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" alloFullScreen></iframe>
      </div>
    </div>
  );
};

export default Highlight;
