import React from 'react';

import Attribute from '../Attribute';
import Config from '../../config.json';
import * as styles from './AttributeGrid.module.css';

const AttributeGrid = (props) => {
  return (
    <div className={styles.root}>
      
      <Attribute
        icon={'pin'}
        title={'Address'}
        subtitle={'1328 W Artesia Blvd, Gardena, CA 90248'}
      />
      <Attribute
        icon={'clock'}
        title={'TC Herbs & Health Hours'}
        subtitle={ Config.hours.map((hour) => (
          <span key={hour}>{hour}<br /></span>
        )) }
      />
      <Attribute
        icon={'phone'}
        title={'Call Us to make an appointment'}
        subtitle={'(310) 327-0336 '}
      />
    </div>
  );
};

export default AttributeGrid;
